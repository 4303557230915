.temperature-selection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    height: 100vh;
    padding: 10px 20px;
    width: 350px;
    background-color: #f9f9f9;
    position: relative;
    right: 0;
    top: 0;
    transition: right 1s ease, width 1s ease;
    z-index: 1;
    svg{
        position: absolute;
        top: 16px;
        left: 16px;
        cursor: pointer;
    }
    .head{
        .title{
            font-size: 20px;
            font-weight: 700;
        }
        .desc{
            font-size: 14px;
        }
    }
    .options{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}
.temperature-selection.hide-selection{
    right: -100%;
    width: 0;
    // min-width: 0;
    // opacity: 0;
}
.response-type-icons{
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-radius: 8px;
    padding: 10px;
    height: fit-content;
    position: absolute;
    right: 0;
    svg{
        height: 44px;
        width: 44px;
        padding: 10px;
        border-radius: 10px;
        background-color: #f9f9f9;
    }
    svg:hover{
        background-color: #d1d1d1;
    }
}
@media (max-width: 1300px){
    .temperature-selection{
        width: 300px;
        // position: absolute;
    }
}

@media (max-width: 1150px){
    .temperature-selection{
        position: absolute;
    }
}