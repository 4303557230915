@import "/src/variables";
@import "/src/index.scss";

.side-bar-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    padding: 16px;
    width: 300px;
    background-color: #f9f9f9;
    position: relative;
    left: 0;
    top: 0;
    z-index: 1;
    transition: all 1s ease;
    p {
        margin: 0;
    }
    .backdrop{
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 1;
    }
    .chats-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: 90%;

        .head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            cursor: pointer;
            // background-color: #e1e1e1;
            border-radius: 8px;

            // padding: 10px;
            svg {
                height: 44px;
                width: 44px;
                padding: 10px;
                border-radius: 10px;
            }
            svg:hover {
                background-color: #d1d1d1;
            }
        }
        .chats-list {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            overflow-y: scroll;
            .time-container {
                display: flex;
                flex-direction: column;

                .time {
                    font-weight: 600;
                    margin-bottom: 6px;
                }
                .chat {
                    display: flex;
                    align-items: center;
                    // gap: 8px;
                    justify-content: space-between;
                    border-bottom: 1px solid #e1e1e1;
                    position: relative;
                    input,
                    p {
                        text-wrap: nowrap;
                        overflow: hidden;
                        width: 100%;
                        padding: 8px 10px;
                        font-size: 14px;
                        border: none;
                        outline: none;
                        background-color: transparent;
                        text-overflow: ellipsis;
                        cursor: pointer;
                    }
                    input.edit {
                        cursor: text;
                    }
                    input.edit ~ svg {
                        height: 30px;
                        width: 30px;
                        path {
                            fill: $--my-green;
                        }
                    }
                    svg {
                        margin: 8px;
                        cursor: pointer;
                        height: 20px;
                        width: 20px;
                    }
                    .more-icon{
                        display: none;
                    }
                    .chat-options{
                        position: absolute;
                        right: 0;
                        top: 30px;
                        display: flex;
                        flex-direction: column;
                        background-color: white;
                        border-radius: 8px;
                        box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
                        z-index: 1;
                        span{
                            padding: 4px 16px;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            font-size: 16px;
                            border-radius: 8px;

                            svg{
                                height: 20px;
                                width: 20px;
                                margin: 0;

                                path{
                                    fill: black;
                                }
                            }
                        }
                        span:hover{
                            background-color: #f9f9f9;
                        }
                    }
                }
                .chat:hover{
                    .more-icon{

                        display: block;
                    }
                }
                .active {
                    background-color: #e1e1e1;
                }
                .chat:hover {
                    background-color: #e1e1e1;
                }
                .chat:last-child {
                    border-bottom: none;
                }
            }
        }
        .chats-list::-webkit-scrollbar {
            width: 0px;
        }

        /* Track */
        .chats-list::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #ffffff;
        }

        /* Handle */
        .chats-list::-webkit-scrollbar-thumb {
            background: #828282;
            border-radius: 10px;
        }

        /* Handle on hover */
        .chats-list::-webkit-scrollbar-thumb:hover {
            background-color: #4b4a4a;
        }
    }
    .login-details {
        .details {
            p {
                font-size: 16px;
                margin-bottom: 4px;
                span {
                    font-weight: 600;
                }
            }
            button {
                width: 100%;
            }
        }
    }
}

.side-bar-main.hide-sidebar {
    left: -100%;
    width: 0px;
}
.sidebar-icons {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-radius: 8px;
    padding: 10px;
    height: fit-content;
    position: absolute;
    svg {
        height: 44px;
        width: 44px;
        padding: 10px;
        border-radius: 10px;
    }
    svg:hover {
        background-color: #d1d1d1;
    }
}
@media (max-width: 1300px) {
    .side-bar-main {
        width: 300px;
    }
}
@media (max-width: 1150px){
    .side-bar-main{
        position: absolute;
    }
}
@media (max-width: 1000px){
    .side-bar-main{
        .chats-container{
            .chats-list{
                .time-container{
                    .chat{
                        .more-icon{
                            display: block;
                        }
                    }
                }
            }
        }
    }
}