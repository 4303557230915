@import "/src/variables";
@import "/src/index.scss";

.login-main{
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 12px;
    background: var(--base-white, #FFF);
    // box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.10);
    position: absolute;
    padding: 20px;
    width: 320px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    p{
        margin: 0;
    }
    .cross{
        height: 24px;
        width: 24px;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
    
    .heading{
        h2{
            margin: 0;
            color: var(--gray-900, #101828);
            font-size: 30px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 8px;
        }
        p{
            margin: 0;
            color: var(--gray-600, #475467);
            font-size: 14px;
            font-weight: 400;
            text-align: center;
        }
    }

    .body{
        display: flex;
        flex-direction: column;
        gap: 24px;
        form{
            display: flex;
            flex-direction: column;
            gap: 12px;
            .input-grp{
                display: flex;
                flex-direction: column;
                label{
                    color: #344054;
                    font-size: 16px;
                    font-weight: 600;
                    margin: 0;
                }
                input{
                    border-radius: var(--spacing-0, 4px);
                    border: 1px solid #D0D5DD;
                    background: var(--white, #FFF);
                    padding: 12px var(--spacing-2, 16px);
                    outline: none;
                    width: 100%;
                }
                .password-input{
                    border-radius: var(--spacing-0, 4px);
                    border: 1px solid #D0D5DD;
                    display: flex;
                    align-items: center;
                    padding-right: 12px;
                    input{
                        border: none;
                    }
                }
            }
            .remember-forgot{
                display: flex;
                justify-content: flex-end;
                .remember{
                    display: flex;
                    gap: 4px;
                    cursor: pointer;
                    .check-box{
                        display: inline-block;
                        height: 16px;
                        width: 16px;
                        border: 1px solid $--my-black;
                        border-radius: 4px;
                        display: grid;
                        place-items: center;
                    }
                    .checked{
                        background-color: $--my-black;
                        svg{
                            path{
                                fill: #FFF;
                            }
                        }
                    }
                }
                p{
                    margin: 0;
                    color: var(--gray-700, #344054);
                    font-size: 12px;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
        }
        p.new-user{
            margin: 0;
            text-align: center;
            color: var(--text, #8D8E89);
            font-size: 14px;
            font-weight: 400;
            .bold{
                color: $--my-black;
                cursor: pointer;
            }
        }
        .or{
            display: flex;
            gap: 8px;
            align-items: center;
            hr{
                width: 100%;
                margin: 0;
            }
            span{
                color: var(--gray-600, #475467);
                font-size: 14px;
                font-weight: 600;
                margin: 0;
            }
        }
        .social-login{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;

            span{
                height: 50px;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                gap: 16px;
                font-size: 16px;
                font-weight: 500;
                padding: 10px 20px;
                align-items: center;
                border-radius: 8px;
                cursor: pointer;
                border: 1px solid #D0D5DD;
                // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                
            }
        }
    }
}