@import "./variables";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $--primary-font-family;
}

.react-tooltip{
  background-color: #e1e1e1 !important;
  max-width: 250px;
  margin-left: 10px;
}
body {
  overflow-x: hidden;
}
p {
  margin: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.primary{
  padding: 10px 18px;
  border-radius: 8px;
  background: $--my-red;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  border: none;
  outline: none;
}
.primary:hover{
  background-color: #AB1318;
}
.secondary{
  padding: 10px 18px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #C7C7CD;
  background-color: white;
}
.secondary:hover{
  background-color: #AB1318DD;
  color: #FFFFFF;
}
.secondary[disabled]{
  background-color: white;
  color: #C7C7CD;
  cursor: not-allowed;
}
.bold{
  font-weight: 600;
}
.loading-cards{
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  min-height: 70px;
  overflow: hidden;
  padding: 0 10px;
  margin-bottom: 90px;
  .loading-card{
    background: linear-gradient(to right, #F0F0F0, #d9d9d9, #F0F0F0);
    background-size: 200%;
    min-width: 200px;
    width: 80%;
    min-height: 10px;
    height: 30px;
    padding: 0px;
    border-radius: 4px;
    animation: loading 1s infinite;
  }
  .loading-card-1{
    min-width: 200px;
    width: 90%;
  }
  .loading-card-2{
    min-width: 200px;
    width: 70%;
  }
}
@keyframes loading {
  0% {
      background-position: -100%;
  }
  100% {
      background-position: 100%;
  }
}
.form-error{
  margin: 0;
  color: red;
  font-size: 12px;
}