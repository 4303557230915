@import "/src/variables";
@import "/src/index.scss";

.nav-main {
    min-width: min(100% - 100px, 500px);
    width: min(80%, 1000px);
    // margin: 0 auto;
    padding: 20px 20px 0 20px;

    .back-drop{
        background-color: #00000044;
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }
    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .bot-selection {
            position: relative;
            .wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                .selected {
                    color: #344054;
                    font-size: 24px;
                    font-weight: 600;
                    margin: 0;
                }
                svg {
                    height: 30px;
                    width: 30px;
                    path {
                        fill: #344054;
                    }
                }
            }
            .options{
                position: absolute;
                display: flex;
                flex-direction: column;
                gap: 8px;
                z-index: 1;
                background-color: #f9f9f9;
                padding: 10px;
                border-radius: 4px;
                right: 0;
                border-radius: 8px;
                z-index: 3;
                p{
                    text-align: left;
                    padding: 4px 10px;
                    border: 1px solid transparent;
                    border-radius: 8px;
                    cursor: pointer;
                }

                p:hover{
                    border: 1px solid #e9e9e9;
                }
            }
        }
    }
    p {
        text-align: center;
    }
}
@media (max-width: 1300px) {
    .nav-main {
        width: min(80%, 1000px);
    }
}

@media (max-width: 1150px) {
    .nav-main {
        width: calc(100% - 100px);
        padding: 10px;
        .head{
            .logo{
                width: 110px;
            }
            .bot-selection{
                .wrapper{
                    .selected{
                        font-size: 16px;
                    }
                    svg {
                        height: 20px;
                        width: 20px;
                    }
                }
            }
        }
    }
}
